import React, { lazy, Suspense } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import AuthProvider from "./components/context/AuthProvider";

import "./App.scss";

const Monitor = lazy(() => import("./components/monitor/Monitor"));
const Admin = lazy(() => import("./components/admin/Admin"));
const Login = lazy(() => import("./components/admin/Login"));

function App() {
  return (
    <div className="App">
      <Router>
        <Suspense fallback={<div>Loading...</div>}>
          <Switch>
            <Route path="/monitor/:id" component={Monitor} />
            <AuthProvider>
              <Route path="/admin" component={Admin} />
              <Route path="/login" component={Login} />
            </AuthProvider>
          </Switch>
        </Suspense>
      </Router>
    </div>
  );
}

export default App;
