import React, { useState } from "react";
import AuthContext from "./Auth.context";

const AuthProvider = ({ children }) => {
  const change = (currToken) => {
    setToken((prevState) => {
      return {
        ...prevState,
        token: currToken,
      };
    });
  };

  const initialState = {
    token: "DefaultToken",
    change,
  };

  const [token, setToken] = useState(initialState);

  return <AuthContext.Provider value={token}>{children}</AuthContext.Provider>;
};

export default AuthProvider;
